import { useEffect } from 'react'

import { useRouter } from 'next/router'

/*
    Because of a nextjs issue when navigating client-side (e.g. via a button wrapped in next/link),
    we need to force load the Dianomi script on every page load. This is because the script
    needs to find the DOM elements that it is hooked into (i.e. the ad slots) to display the ads.
**/
export const useDianomiScript = () => {
    const router = useRouter()

    useEffect(() => {
        const loadScript = () => {
            const existingScript = document.getElementById('dianomi_context_script')
            if (existingScript) {
                document.body.removeChild(existingScript)
            }

            const script = document.createElement('script')
            script.src = 'https://www.dianomi.com/js/contextfeed.js'
            script.id = 'dianomi_context_script'
            script.type = 'text/javascript'
            script.async = true
            script.defer = true

            document.body.appendChild(script)
        }

        loadScript()

        router.events.on('routeChangeComplete', loadScript)

        return () => {
            router.events.off('routeChangeComplete', loadScript)
            const existingScript = document.getElementById('dianomi_context_script')
            if (existingScript) {
                document.body.removeChild(existingScript)
            }
        }
    }, [router])
}

export default useDianomiScript
