/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SharedLayoutBlockFragment_block = {
    readonly type?: string | undefined;
    readonly layoutComponents?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
            } | null;
        } | null>;
    } | null | undefined;
    readonly id?: string | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ContentPieceSection_contentPieceSelection" | "LayoutComponentSection_section">;
    readonly " $refType": "SharedLayoutBlockFragment_block";
};
export type SharedLayoutBlockFragment_block$data = SharedLayoutBlockFragment_block;
export type SharedLayoutBlockFragment_block$key = {
    readonly " $data"?: SharedLayoutBlockFragment_block$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SharedLayoutBlockFragment_block">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedLayoutBlockFragment_block",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LayoutComponentConnection",
          "kind": "LinkedField",
          "name": "layoutComponents",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LayoutComponentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LayoutComponentSection_section"
        }
      ],
      "type": "LayoutComponentSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "FlexibleContentSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "ContentCollectionSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "ContentTagSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "GeographyTagSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "AssetClassTagSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "DynamicContentSelection",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentPieceSection_contentPieceSelection"
    }
  ],
  "type": "SelectionRowBlock",
  "abstractKey": "__isSelectionRowBlock"
};
})();
(node as any).hash = '66dc43df232849ba6102940fb0d17625';
export default node;
