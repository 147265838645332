export interface HasContentAccessProps {
    hasPremiumSubscription: boolean
    isPreviewingContentAllowance: boolean
    isPreviewing: boolean
    isPremium: boolean
    isFreemium: boolean
    isAvailableForFreemium: boolean
}

export const hasContentAccess = ({
    hasPremiumSubscription,
    isPreviewingContentAllowance,
    isPreviewing,
    isPremium,
    isFreemium,
    isAvailableForFreemium,
}: HasContentAccessProps): boolean => {
    return (
        hasPremiumSubscription ||
        isPreviewingContentAllowance ||
        isPreviewing ||
        !isPremium ||
        (isFreemium && isAvailableForFreemium)
    )
}
