/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type MainLayoutBlocksFragment_mainLayoutBlocks = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly type?: string | undefined;
            readonly id?: string | undefined;
            readonly layoutComponents?: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly __typename: string;
                    } | null;
                } | null>;
            } | null | undefined;
            readonly " $fragmentRefs": FragmentRefs<"SharedLayoutBlockFragment_block" | "LayoutComponentSection_section">;
        };
    }>;
    readonly " $refType": "MainLayoutBlocksFragment_mainLayoutBlocks";
};
export type MainLayoutBlocksFragment_mainLayoutBlocks$data = MainLayoutBlocksFragment_mainLayoutBlocks;
export type MainLayoutBlocksFragment_mainLayoutBlocks$key = {
    readonly " $data"?: MainLayoutBlocksFragment_mainLayoutBlocks$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MainLayoutBlocksFragment_mainLayoutBlocks">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainLayoutBlocksFragment_mainLayoutBlocks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectionRowBlockNonNullEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LayoutComponentConnection",
                  "kind": "LinkedField",
                  "name": "layoutComponents",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "LayoutComponentEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": null,
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__typename",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LayoutComponentSection_section"
                }
              ],
              "type": "LayoutComponentSelection",
              "abstractKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SharedLayoutBlockFragment_block"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SelectionRowBlockConnection",
  "abstractKey": null
};
(node as any).hash = '383f5070312bc69f4ff79370c2d30d8a';
export default node;
