import { graphql, useFragment } from 'react-relay'

import BannerAdvertBase from './Adverts/BannerAdvertBase'
import AdvertWrapper from './AdvertWrapper'

import type { BannerAdvert_activeProductAdvert$key } from '__generated__/BannerAdvert_activeProductAdvert.graphql'
import ExternalBannerAd from 'components/Advertising/External/ExternalBannerAd'
import Hide from 'components/Hide'
import AccessibleImage from 'components/Image/Relay/AccessibleImage'

type Props = {
    advert: BannerAdvert_activeProductAdvert$key | null
    isAboveTheFold?: boolean
}

const hideImageOnDesktop = [false, false, true, true, true]
const hideImageOnMobile = hideImageOnDesktop.map(bool => !bool)

const BannerAdvert = ({ advert: advertKey, isAboveTheFold }: Props) => {
    const advert = useFragment(BannerAdvertFragment, advertKey)

    // If there's no internal advert, show a 3rd party one
    if (!advert || !advert.wideImage || !advert.narrowImage) return <ExternalBannerAd />

    const image = (
        <>
            <Hide on={hideImageOnMobile}>
                <AccessibleImage
                    accessibleImage={advert.wideImage}
                    fill
                    priority={!!isAboveTheFold}
                ></AccessibleImage>
            </Hide>
            <Hide on={hideImageOnDesktop}>
                <AccessibleImage
                    accessibleImage={advert.narrowImage}
                    fill
                    priority={!!isAboveTheFold}
                ></AccessibleImage>
            </Hide>
        </>
    )

    return (
        <AdvertWrapper advert={advert}>
            <BannerAdvertBase
                title={advert.title}
                description={advert.description}
                image={image}
                companyName={advert.sponsoringCompany?.name}
            ></BannerAdvertBase>
        </AdvertWrapper>
    )
}

const BannerAdvertFragment = graphql`
    fragment BannerAdvert_activeProductAdvert on ProductAdvert {
        title
        description
        destinationUrl
        sponsoringCompany {
            name
        }
        wideImage {
            ...AccessibleImage_accessibleImage
        }
        narrowImage {
            ...AccessibleImage_accessibleImage
        }
        ...AdvertWrapper_activeProductAdvert
    }
`

export default BannerAdvert
