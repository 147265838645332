import type { GetStaticPropsContext } from 'next'
import { graphql } from 'react-relay'

import type {
    pages_HomeQuery,
    pages_HomeQueryResponse,
} from '__generated__/pages_HomeQuery.graphql'
import Home from 'containers/home'
import { getStaticPropsShared } from 'utils/page/getStaticPropsShared'
import type { StaticPageProps } from 'utils/page/StaticPageProps'
import StaticPageWrapper from 'utils/page/StaticPageWrapper'

const HomePage = ({
    viewer,
    queryRecords,
    relay,
}: StaticPageProps<pages_HomeQueryResponse['viewer']>) => {

    return (
        <StaticPageWrapper<pages_HomeQuery>
            relay={relay}
            viewer={viewer}
            queryRecords={queryRecords}
            relayQuery={HomeQuery}
            PageComponent={Home}
            variables={{ }}
        />
    )
}

const HomeQuery = graphql`
    query pages_HomeQuery {
        viewer {
            ...home_viewer
            ...useIsAuthenticated_viewer
        }
    }
`

export const getStaticProps = async (context: GetStaticPropsContext) => {
    return getStaticPropsShared({
        context,
        relayQuery: HomeQuery,
        options: {
            revalidate: 15 * 60, // 15 minutes
        },
    })
}

export default HomePage
