import type { ReactElement } from 'react'

import { graphql, useFragment } from 'react-relay'

import type { BannerAdvertLayoutComponent_productAdvert$key } from '__generated__/BannerAdvertLayoutComponent_productAdvert.graphql'
import BannerAdvert from 'components/Advertising/BannerAdvert'
import Box from 'components/Primitives/Box'

type BannerAdvertLayoutComponentProps = {
    // Fragment key for advert data
    advert: BannerAdvertLayoutComponent_productAdvert$key
    priorityImageLoad: boolean
}

const BannerAdvertLayoutComponent = (
    props: BannerAdvertLayoutComponentProps,
): ReactElement | null => {
    const advert = useFragment(BannerAdvertLayoutComponentFragment, props.advert)
    return (
        <Box width="100%" maxWidth="48rem" margin="auto">
            <BannerAdvert advert={advert} isAboveTheFold={props.priorityImageLoad}></BannerAdvert>
        </Box>
    )
}

const BannerAdvertLayoutComponentFragment = graphql`
    fragment BannerAdvertLayoutComponent_productAdvert on ProductAdvert {
        ...BannerAdvert_activeProductAdvert
    }
`

export default BannerAdvertLayoutComponent
