import { useEffect } from 'react'

import styled from 'styled-components'

import SmallAd from 'components/Advertising/External/ExternalSmallAd'
import SmallBannerAd from 'components/Advertising/External/ExternalSmallBannerAd'
import Hide from 'components/Hide'
import FullWidthBackground from 'containers/FullWidthBackground'
import { trackExternalProductAdvert } from 'lib/analyticsApi'

const ExternalBannerAd = () => {
    useEffect(() => {
        trackExternalProductAdvert('Impression')
    }, [])

    return (
        <Container backgroundColor="neutral.0">
            <Background>
                <Hide on={[true, true, false, false, false]}>
                    <SmallBannerAd />
                </Hide>
                <Hide on={[false, false, true, true, true]}>
                    <SmallAd />
                </Hide>
            </Background>
        </Container>
    )
}

const Container = styled(FullWidthBackground)`
    margin-bottom: ${({ theme }) => theme.space[2]};
`

const Background = styled.div`
    background-color: ${({ theme }) => theme.palette.neutral[50]};
    padding: ${({ theme }) => theme.space[1]} 0;
`

export default ExternalBannerAd
