import { graphql, useFragment } from 'react-relay'

import TodayIn10Min from '../modules/TodayIn10Min'

import MaxWidthContainer from './MaxWidthContainer'
import PaddedPageContainer from './PaddedPageContainer'

import type { home_viewer$key } from '__generated__/home_viewer.graphql'
import Box from 'components/Primitives/Box'
import MainLayoutBlocks from 'containers/Hubs/MainLayoutBlocks'
import { DefaultLayout } from 'layouts'
import Error from 'pages/_error'
import type { PageComponentProps } from 'utils/page/StaticPageWrapper'

const Home = ({ viewer: viewerKey, isAuthenticated }: PageComponentProps<home_viewer$key>) => {
    const viewer = useFragment(HomeFragment, viewerKey)

    const homePage = viewer.webLayouts?.homePage

    if (!homePage) return <Error />

    const hasPremiumSubscription = !!viewer.me?.authorization?.roles?.hasPremiumSubscription
    const authorization = viewer.authorization

    const { mainLayoutBlocks } = homePage

    const HOME_EXPLORE_MORE_BACKGROUND_COLOR = 'neutral.50'

    return (
        <DefaultLayout
            isAuthenticated={isAuthenticated}
            hasPremiumSubscription={hasPremiumSubscription}
            seoProps={{ noindex: false }}
            bgColor={HOME_EXPLORE_MORE_BACKGROUND_COLOR}
            viewer={viewer}
            showCTABanner
        >
            <TodayIn10Min viewer={viewer} authorization={authorization} />
            <PaddedPageContainer pageName="home">
                <MaxWidthContainer centraliseChildren>
                    <Box pb={1} px={[0, 0, 2, 2]}>
                        <MainLayoutBlocks
                            mainLayoutBlocks={mainLayoutBlocks}
                            authorization={authorization}
                        />
                    </Box>
                </MaxWidthContainer>
                <Box pb={2} px={[0, 0, 2, 2]}>
                    <iframe
                        src="https://open.spotify.com/embed/show/4NnfkFnczUPV06Nl6PDhZO?utm_source=generator"
                        width="100%"
                        height="352"
                        frameBorder="0"
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                        name="Play the Finimize podcast via Spotify"
                    ></iframe>
                </Box>
            </PaddedPageContainer>
        </DefaultLayout>
    )
}

const HomeFragment = graphql`
    fragment home_viewer on Viewer {
        me {
            authorization {
                roles {
                    hasPremiumSubscription
                }
            }
        }

        authorization {
            ...authorization_authorization
        }
        ...DefaultLayout_viewer

        webLayouts {
            homePage {
                mainLayoutBlocks {
                    edges {
                        node {
                            ...ContentPieceSection_contentPieceSelection
                        }
                    }
                    ...MainLayoutBlocksFragment_mainLayoutBlocks
                }
            }
        }
        ...TodayIn10Min_viewer
    }
`

export default Home
