import type { ReactElement } from 'react'

import { graphql, useFragment } from 'react-relay'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { MainLayoutBlocksFragment_mainLayoutBlocks$key } from '__generated__/MainLayoutBlocksFragment_mainLayoutBlocks.graphql'
import SharedLayoutBlock from 'containers/SharedLayoutBlock'

type MainLayoutBlocksProps = {
    authorization: authorization_authorization$key
    mainLayoutBlocks: MainLayoutBlocksFragment_mainLayoutBlocks$key
}

const MainLayoutBlocks = ({ authorization, mainLayoutBlocks }: MainLayoutBlocksProps) => {
    const layoutBlocks = useFragment(MainLayoutBlocksFragment, mainLayoutBlocks)

    const renderedBlocks: ReactElement[] = []

    // Iterate through mainLayout blocks and:
    // - add layout component sections
    // - add content piece sections
    // - priority load the first section with images

    let foundFirstNonAlertRow = false

    layoutBlocks.edges.map((edge, index) => {
        const { node } = edge

        // For SEO performance reasons, we want to priority load the first section that
        // can have images. Alerts do not have images, so we skip them.
        let priorityImageLoad = false
        if (
            node.layoutComponents?.edges[0]?.node?.__typename !== 'AlertLayoutComponent' &&
            !foundFirstNonAlertRow
        ) {
            // If we have a non-alert section, we want to priority load the images
            priorityImageLoad = true
            // Set this to true so that this code only runs once and priorityImageLoad is set to false again
            foundFirstNonAlertRow = true
        }

        renderedBlocks.push(
            // The type check & conditional return is inside the shared layout block component
            <SharedLayoutBlock
                key={`${node.id}-${index}`}
                node={node}
                index={index}
                priorityImageLoad={priorityImageLoad}
                authorization={authorization}
            />,
        )
    })
    return <>{renderedBlocks}</>
}

const MainLayoutBlocksFragment = graphql`
    fragment MainLayoutBlocksFragment_mainLayoutBlocks on SelectionRowBlockConnection {
        edges {
            node {
                ... on LayoutComponentSelection {
                    type
                    id
                    layoutComponents {
                        edges {
                            node {
                                __typename
                            }
                        }
                    }
                    ...LayoutComponentSection_section
                }
                ...SharedLayoutBlockFragment_block
            }
        }
    }
`

export default MainLayoutBlocks
