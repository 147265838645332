import type { ReactElement } from 'react'

import { graphql, useFragment } from 'react-relay'

import LayoutComponentSectionUI from '..'

import type { LayoutComponentSection_section$key } from '__generated__/LayoutComponentSection_section.graphql'
import AlertLayoutComponent from 'components/LayoutComponents/Alert/Relay/AlertLayoutComponent'
import BannerAdvert from 'components/LayoutComponents/BannerAdvert/BannerAdvertLayoutComponent'
import GreetingCardLayoutComponent from 'components/LayoutComponents/GreetingCard/Relay/GreetingCardLayoutComponent'
import PromoCardLayoutComponent from 'components/LayoutComponents/PromoCard/Relay/PromoCardLayoutComponent'

type LayoutComponentSectionProps = {
    section: LayoutComponentSection_section$key
    priorityImageLoad: boolean
}

const LayoutComponentSection = (props: LayoutComponentSectionProps): ReactElement | null => {
    const section = useFragment(LayoutComponentSectionFragment, props.section)

    if (!section.layoutComponents) return null

    const { priorityImageLoad } = props

    const components: ReactElement[] = []

    const numComponentsInSection = section.layoutComponents.edges.length

    section.layoutComponents.edges.forEach((component, index) => {
        if (!component?.node) return

        const key = `${index}|${component.node.__typename}`
        if (component.node.__typename === 'AlertLayoutComponent') {
            components.push(<AlertLayoutComponent alert={component.node} key={key} />)
        }
        if (component.node.__typename === 'PromoCardLayoutComponent') {
            components.push(
                <PromoCardLayoutComponent
                    promoCard={component.node}
                    key={key}
                    priorityImageLoad={priorityImageLoad}
                    numComponentsInSection={numComponentsInSection}
                />,
            )
        }
        if (component.node.__typename === 'GreetingLayoutComponent') {
            components.push(
                <GreetingCardLayoutComponent
                    greetingCard={component.node}
                    key={key}
                    priorityImageLoad={priorityImageLoad}
                    numComponentsInSection={numComponentsInSection}
                />,
            )
        }
        if (component.node.__typename === 'ProductAdvert') {
            components.push(
                <BannerAdvert
                    key={key}
                    advert={component.node}
                    priorityImageLoad={priorityImageLoad}
                />,
            )
        }
    })

    return <LayoutComponentSectionUI>{components}</LayoutComponentSectionUI>
}

const LayoutComponentSectionFragment = graphql`
    fragment LayoutComponentSection_section on LayoutComponentSelection {
        layoutComponents {
            edges {
                node {
                    __typename
                    ...AlertLayoutComponent_alert
                    ...PromoCardLayoutComponent_promoCard
                    ...GreetingCardLayoutComponent_greetingCard
                    ...BannerAdvertLayoutComponent_productAdvert
                }
            }
        }
    }
`

export default LayoutComponentSection
