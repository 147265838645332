import { graphql, useFragment } from 'react-relay'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { SharedLayoutBlockFragment_block$key } from '__generated__/SharedLayoutBlockFragment_block.graphql'
import LayoutComponentSection from 'components/LayoutComponents/LayoutComponentSection/Relay/LayoutComponentSection'
import ContentPieceSection from 'modules/ContentPieceSection'

const contentPieceLists = [
    'geography_tag',
    'content_collection',
    'asset_class_tag',
    'content_tag',
    'flexible_content_selection',
    'dynamic_content_selection',
]

type SharedLayoutBlockProps = {
    node: SharedLayoutBlockFragment_block$key
    index: number
    priorityImageLoad: boolean
    authorization: authorization_authorization$key
}

const SharedLayoutBlock = ({
    node,
    index,
    priorityImageLoad,
    authorization,
}: SharedLayoutBlockProps) => {
    const block = useFragment(SharedLayoutBlockFragment, node)

    if (block.type === 'layout_component_section') {
        return (
            <LayoutComponentSection
                key={`${block.id}-${index}`}
                section={block}
                priorityImageLoad={priorityImageLoad}
            />
        )
    }

    if (block.type && contentPieceLists.includes(block.type)) {
        return (
            <ContentPieceSection
                key={`${block.id}-${index}`}
                contentPieceSelection={block}
                authorization={authorization}
                isAboveTheFold={priorityImageLoad}
            />
        )
    }

    return null
}

const SharedLayoutBlockFragment = graphql`
    fragment SharedLayoutBlockFragment_block on SelectionRowBlock {
        ... on LayoutComponentSelection {
            ...LayoutComponentSection_section
            type
            layoutComponents {
                edges {
                    node {
                        __typename
                    }
                }
            }
        }
        ... on FlexibleContentSelection {
            id
            type
        }
        ... on ContentCollectionSelection {
            id
            type
        }
        ... on ContentTagSelection {
            id
            type
        }
        ... on GeographyTagSelection {
            id
            type
        }
        ... on AssetClassTagSelection {
            id
            type
        }
        ... on DynamicContentSelection {
            id
            type
        }
        ...ContentPieceSection_contentPieceSelection
    }
`

export default SharedLayoutBlock
