import styled from 'styled-components'

/**
 * @returns Thin banner avert (Dianomi 728 x 90)
 */
const ExternalSmallBannerAd = () => {
    return (
        <Container id="small-banner-ad">
            <div className="dianomi_context" data-dianomi-context-id="3337"></div>
        </Container>
    )
}

export default ExternalSmallBannerAd

const Container = styled.div`
    width: 728px;
    height: 90px;
    margin: 0 auto;
`
