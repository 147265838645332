import CarouselSection from './CarouselSection'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { ContentPieceSection_contentPieceSelection } from '__generated__/ContentPieceSection_contentPieceSelection.graphql'
import CarouselHighlightCard from 'components/ContentCards/CarouselHighlightCard/CarouselHighlightCard'
import CarouselSquareImageCard from 'components/ContentCards/CarouselSquareImageCard/CarouselSquareImageCard'
import ContentPieceCardWrapper from 'components/ContentCards/DataWrappers/Relay/ContentPieceCardWrapper'
import { CAROUSEL_IMAGES_ON_SCREEN } from 'components/Image/consts/imagePriority'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'

export const CAROUSEL_CONTENT_CARD_WIDTH = 272
export const CAROUSEL_CONTENT_CARD_HEIGHT = 380

type ContentPieceList = ContentPieceSection_contentPieceSelection['contentPieceList']

type Props = {
    title: string
    subtitle?: string
    cardStyle?: string
    contentPieceList: ContentPieceList
    // showMostRead: boolean // do we still need this
    // Fragment key for user authorization data
    authorization: authorization_authorization$key
    /**
     * Does this content piece section appear above the fold? https://en.wikipedia.org/wiki/Above_the_fold
     */
    isAboveTheFold?: boolean
} & BoxProps

// Carousel Relay component

const ContentPieceCarouselSection = (props: Props) => {
    const { title, subtitle, cardStyle, contentPieceList, ...containerStyleProps } = props

    if (!contentPieceList) return null

    const children = contentPieceList.edges.map((contentPiece, index) => {
        const imagePriority = !!props.isAboveTheFold && index < CAROUSEL_IMAGES_ON_SCREEN

        const CardComponent =
            cardStyle === 'simplified'
                ? // "Simplified" cards are used for those with square images
                  CarouselSquareImageCard
                : CarouselHighlightCard

        return (
            <ContentPieceCardWrapper
                CardComponent={CardComponent}
                key={contentPiece.node.id || index}
                contentPiece={contentPiece.node}
                authorization={props.authorization}
                priorityImageLoad={imagePriority}
            />
        )
    })

    return (
        <Box p={0} pt={1} pb="1.25rem" {...containerStyleProps}>
            <CarouselSection title={title} subtitle={subtitle}>
                {children}
            </CarouselSection>
        </Box>
    )
}

export default ContentPieceCarouselSection
