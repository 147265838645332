import styled from 'styled-components'

/**
 * @returns Small advert (Dianomi 300 x 250)
 */
const ExternalSmallAd = () => {
    return (
        <Container id="small-ad">
            <div className="dianomi_context" data-dianomi-context-id="3394"></div>
        </Container>
    )
}

export default ExternalSmallAd

const Container = styled.div`
    width: 300px;
    height: 250px;
    margin: 0 auto;
`
